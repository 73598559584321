import * as React from "react";
import { Tooltip } from "./tooltip";
import { clsx } from "../lib/functions";
import { jsx, jsxs } from "react/jsx-runtime";
const Radio = ({
  label,
  onChange,
  value,
  options,
  className,
  hintText,
  layout = "horizontal"
}) => {
  const prefixId = React.useId();
  return /* @__PURE__ */ jsxs("div", {
    className: clsx(`mec-radio mec-radio--${layout}`, className),
    children: [
      label && /* @__PURE__ */ jsxs("div", {
        className: `mec-field__label`,
        children: [
          /* @__PURE__ */ jsx("span", {
            className: `mec-field__label__txt`,
            children: label
          }),
          hintText && /* @__PURE__ */ jsx(Tooltip, {
            content: hintText
          })
        ]
      }),
      /* @__PURE__ */ jsx("div", {
        className: `mec-radio__field_group`,
        children: options.map(({ label: label2, value: optionValue }, index) => {
          return /* @__PURE__ */ jsxs("div", {
            className: `mec-radio__field_group__item`,
            children: [
              /* @__PURE__ */ jsx("input", {
                type: "radio",
                id: `${prefixId}-${index}`,
                checked: optionValue === value,
                onChange: (ev) => {
                  if (ev.target.checked) {
                    onChange(optionValue);
                  }
                }
              }),
              /* @__PURE__ */ jsx("span", {
                className: `mec-radio__field_group__item_radio`
              }),
              /* @__PURE__ */ jsx("label", {
                htmlFor: `${prefixId}-${index}`,
                children: label2
              })
            ]
          }, label2);
        })
      })
    ]
  });
};
export {
  Radio
};
